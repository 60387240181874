import {FC} from 'react';

interface BottomBarProps {
  backgroundColor?: string;
}

export const BottomBar: FC<BottomBarProps> = props => {
  const {backgroundColor} = props;
  const insets = {bottom: 0};
  return (
    <div
      key="bottom-bar"
      style={{
        height: insets.bottom,
        backgroundColor,
      }}
    />
  );
};
BottomBar.displayName = 'BottomBar';
