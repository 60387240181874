import {FC} from 'react';
import {styled} from 'styled-components';
import {Route} from 'wouter';

import {appBackgroundColor, spacing, topBarBackgroundColor} from '@src/lib/theme';
import {Accueil} from '@src/pages/accueil';
import {Edition} from '@src/pages/edition';
import {Game} from '@src/pages/game';
import {GamesSelection} from '@src/pages/games_selection';
import {KingsPull} from '@src/pages/kings_pull';
import {Round} from '@src/pages/round';
import {Selection} from '@src/pages/selection';
import {Tirage} from '@src/pages/tirage';

export const App: FC = () => {
  return (
    <AppWrapper>
      <AppContainer>
        <Route path="/" component={Accueil} />
        <Route path="/accueil" component={Accueil} />
        <Route path="/selection" component={Selection} />
        <Route path="/edition" component={Edition} />
        <Route path="/tirage" component={Tirage} />
        <Route path="/games-selection" component={GamesSelection} />
        <Route path="/game/:gameId" component={Game} />
        <Route path="/game/:gameId/round" component={Round} />
        <Route path="/game/:gameId/round/:hand" component={Round} />
        <Route path="/tirage-rois" component={KingsPull} />
      </AppContainer>
    </AppWrapper>
  );
};
App.displayName = 'App';

const AppWrapper = styled.div`
  height: 100%;
  background-color: ${topBarBackgroundColor};
`;

const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: ${appBackgroundColor};
  padding-bottom: ${spacing}px;
`;
