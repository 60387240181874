import {CSSProperties, FC} from 'react';

export const VerticalSpacing: FC<{height: number; style?: CSSProperties}> = ({height, style}) => (
  <div style={{width: '100%', height, ...style}} />
);
VerticalSpacing.displayName = 'VerticalSpacing';

// eslint-disable-next-line react/no-multi-comp
export const HorizontalSpacing: FC<{width: number; style?: CSSProperties}> = ({width, style}) => (
  <div style={{height: '100%', width, ...style}} />
);
HorizontalSpacing.displayName = 'HorizontalSpacing';
