import EmojiPicker, {SuggestionMode} from 'emoji-picker-react';
import {FC, useState} from 'react';
import {styled} from 'styled-components';
import {useLocation} from 'wouter';

import {accountPlusIcon} from '@shared-web/components/icons/mui/account_plus_icon';
import {arrowLeftIcon} from '@shared-web/components/icons/mui/arrow_left_icon';
import {trashCanOutlineIcon} from '@shared-web/components/icons/mui/trash_can_outline_icon';

import {BottomBar} from '@src/components/bottom_bar';
import {CustomButton} from '@src/components/custom_buttons';
import {VerticalSpacing} from '@src/components/spacing';
import {TopBar} from '@src/components/top_bar';
import {initialName} from '@src/lib/constants';
import {
  addPlayer,
  delPlayer,
  getPlayers,
  Player,
  setPlayerEmoji,
  setPlayers,
} from '@src/lib/stores/players_store';
import {
  appBackgroundColor,
  borderRadius,
  buttonHeight,
  fontSizes,
  inputBackgroundColor,
  pastilleBackgroundColor,
  spacing,
  topBarButtonWidth,
  topBarColor,
} from '@src/lib/theme';
import {useSortedPlayers} from '@src/lib/utilities';

const onPressAddPlayer = (): void => {
  addPlayer();
};

export const Edition: FC = () => {
  const setLocation = useLocation()[1];
  const [emojiPickerPlayer, setEmojiPickerPlayer] = useState<Player>();
  const sortedPlayers = useSortedPlayers();

  const onPressDeletePlayer = (p: Player): void => {
    if (p.name !== initialName) {
      // eslint-disable-next-line no-restricted-globals
      const choice = confirm(`Voulez-vous supprimer ${p.name} ?`);
      if (choice) {
        delPlayer(p);
      }
    } else {
      delPlayer(p);
    }
  };

  const handlePlayerEmojiSelected = (player: Player, emoji: string): void => {
    setPlayerEmoji(emoji, player);
    setEmojiPickerPlayer(undefined);
  };

  const handlePlayerEmojiPress = (player: Player): void => {
    setEmojiPickerPlayer(player);
  };

  const onTextChange = (text: string, player: Player): void => {
    player.name = text;
    const current_players = getPlayers();
    setPlayers(current_players.map(p => (p.id === player.id ? {...p, name: text} : p)));
  };

  const scrollViewContent: JSX.Element[] = [];
  let firstPlayer = true;
  for (const p of sortedPlayers) {
    if (firstPlayer) {
      firstPlayer = false;
    } else {
      scrollViewContent.push(<VerticalSpacing key={p.id * p.id} height={spacing} />);
    }
    scrollViewContent.push(
      <PlayerWrapper key={p.id}>
        <PlayerEmoji onClick={() => handlePlayerEmojiPress(p)}>{p.emoji}</PlayerEmoji>
        <TextInputPlayer
          onFocus={evt => evt.currentTarget.select()}
          onChange={evt => onTextChange(evt.currentTarget.value, p)}
          defaultValue={p.name}
        />
        <CustomButton
          iconSizeRatio={1.2}
          icon={trashCanOutlineIcon}
          size="medium"
          onPress={() => onPressDeletePlayer(p)}
        />
      </PlayerWrapper>
    );
  }

  return (
    <>
      <>
        <TopBar
          middle={<Titre>{`Edition`}</Titre>}
          left={
            <CustomButton
              text="Retour"
              icon={arrowLeftIcon}
              onPress={() => setLocation('accueil')}
              width={topBarButtonWidth}
            />
          }
        />
        <WrapperAdd>
          <CustomButton
            icon={accountPlusIcon}
            text="Ajouter joueur"
            onPress={onPressAddPlayer}
            size="large"
          />
        </WrapperAdd>
        <PlayerContent>
          {scrollViewContent}
          <BottomBar />
        </PlayerContent>
      </>
      {emojiPickerPlayer ? (
        <EmojiWrapper>
          <EmojiPicker
            height={'100%'}
            width={'100%'}
            suggestedEmojisMode={SuggestionMode.FREQUENT}
            // eslint-disable-next-line react/forbid-component-props
            className="emoji-picker"
            onEmojiClick={({emoji}) => handlePlayerEmojiSelected(emojiPickerPlayer, emoji)}
          />
        </EmojiWrapper>
      ) : (
        <></>
      )}
    </>
  );
};
Edition.displayName = 'Edition';

const Titre = styled.span`
  font-size: ${fontSizes.medium}px;
  flex-grow: 1;
  text-align: center;
  color: ${topBarColor};
  margin-right: ${topBarButtonWidth}px;
`;

const WrapperAdd = styled.div`
  margin: ${spacing}px;
  margin-top: 0;
  background-color: transparent;
`;

const PlayerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: ${spacing}px;
  margin: 0px ${spacing}px;
  border-radius: ${borderRadius * 2}px;
  background-color: ${pastilleBackgroundColor};
`;

const PlayerEmoji = styled.span`
  flex-shrink: 0;
  background-color: ${inputBackgroundColor};
  font-size: ${fontSizes.medium}px;
  height: ${buttonHeight.medium}px;
  width: ${buttonHeight.medium}px;
  text-align: center;
  line-height: ${buttonHeight.medium}px;
  border-radius: ${borderRadius}px;
`;

const TextInputPlayer = styled.input`
  flex-grow: 1;
  background-color: ${inputBackgroundColor};
  font-size: ${fontSizes.medium}px;
  height: ${buttonHeight.medium}px;
  border-radius: ${borderRadius}px;
  padding-left: ${spacing}px;
  margin: 0 ${spacing / 2}px;
`;

const EmojiWrapper = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
  background-color: ${appBackgroundColor};
  .emoji-picker .epr-emoji-list > li:first-of-type {
    display: none;
  }
  .emoji-picker > div:last-of-type {
    display: none;
  }
`;

const PlayerContent = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-y: auto;
`;
