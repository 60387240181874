import {FC, useCallback} from 'react';
import {styled} from 'styled-components';
import {useLocation} from 'wouter';

import {accountEditOutlineIcon} from '@shared-web/components/icons/mui/account_edit_outline_icon';
import {diceIcon} from '@shared-web/components/icons/mui/dice_icon';
import {playingCardsIcon} from '@shared-web/components/icons/mui/playing_cards_icon';

import {BottomBar} from '@src/components/bottom_bar';
import {CustomButton} from '@src/components/custom_buttons';
import {VerticalSpacing} from '@src/components/spacing';
import {TopBar} from '@src/components/top_bar';
import {fontSizes, spacing, topBarColor} from '@src/lib/theme';

export const Accueil: FC = () => {
  const setLocation = useLocation()[1];

  const handleEditClick = useCallback(() => setLocation('/edition'), [setLocation]);
  const handleSelectClick = useCallback(() => setLocation('/selection'), [setLocation]);
  const handleGameSelectClick = useCallback(() => setLocation('/games-selection'), [setLocation]);
  const handleKingPullClick = useCallback(() => setLocation('/tirage-rois'), [setLocation]);

  return (
    <>
      <TopBar middle={<Titre>Accueil</Titre>} />
      <WrapperAdd>
        <CustomButton
          text="Coinche"
          size="large"
          icon={playingCardsIcon}
          onPress={handleGameSelectClick}
          style={{height: 100}}
        />
      </WrapperAdd>
      <VerticalSpacing height={spacing} />
      <WrapperAdd>
        <CustomButton
          text="Configuration des joueurs"
          size="large"
          icon={accountEditOutlineIcon}
          onPress={handleEditClick}
        />
      </WrapperAdd>
      <VerticalSpacing height={spacing} />
      <WrapperAdd>
        <CustomButton
          text="Tirer des équipes au hasard"
          size="large"
          icon={diceIcon}
          onPress={handleSelectClick}
        />
      </WrapperAdd>
      <VerticalSpacing height={spacing} />
      <WrapperAdd>
        <CustomButton text="Tirer les rois" size="large" onPress={handleKingPullClick} />
      </WrapperAdd>
      <StyledScrollView>
        <BottomBar />
      </StyledScrollView>
    </>
  );
};
Accueil.displayName = 'Accueil';

const Titre = styled.span`
  font-size: ${fontSizes.medium}px;
  flex-grow: 1;
  text-align: center;
  color: ${topBarColor};
`;

const WrapperAdd = styled.div`
  margin: 0 ${spacing}px;
  flex-shrink: 0;
`;

const StyledScrollView = styled.div`
  flex-grow: 1;
  margin: ${spacing}px;
  margin-bottom: 0;
  overflow: auto;
`;
