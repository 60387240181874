import {FC} from 'react';
import {styled} from 'styled-components';
import {useLocation} from 'wouter';

import {UnthemedButton} from '@shared-web/components/core/button';
import {trashCanOutlineIcon} from '@shared-web/components/icons/mui/trash_can_outline_icon';

import {CustomButton} from '@src/components/custom_buttons';
import {delGame, Game} from '@src/lib/stores/games_store';
import {
  black,
  borderRadius,
  buttonHeight,
  fontSizes,
  pastilleBackgroundColor,
  spacing,
  white,
} from '@src/lib/theme';
import {getScoreWithId} from '@src/lib/utilities';

interface SelectableGameProps {
  game: Game;
}

export const SelectableGame: FC<SelectableGameProps> = props => {
  const {game} = props;
  const setLocation = useLocation()[1];

  //______________ FONCTIONS ______________
  const onPressDeletePlayer = (): void => {
    // eslint-disable-next-line no-restricted-globals
    const choice = confirm(`Voulez-vous supprimer la partie ?`);
    if (choice) {
      delGame(game);
    }
  };
  const handleGamePress = (): void => {
    setLocation(`/game/${game.id}`);
  };

  //______________ HTML ______________
  return (
    <GlobalWrapper>
      <ContentWrapper
        onClick={() => {
          handleGamePress();
        }}
      >
        <TeamWrapper>
          <TeamEmojiPlayer>{game.teams[0]?.players[0]?.emoji}</TeamEmojiPlayer>
          <TeamEmojiPlayer>{game.teams[0]?.players[1]?.emoji}</TeamEmojiPlayer>
        </TeamWrapper>
        <ScoresWrapper>
          <ScoreWrapper
            style={{
              fontWeight:
                (getScoreWithId(game.id)[0] ?? 0) > (getScoreWithId(game.id)[1] ?? 0)
                  ? '600'
                  : '300',
            }}
          >
            {(getScoreWithId(game.id)[0] ?? 0).toLocaleString()}
          </ScoreWrapper>
        </ScoresWrapper>
        <ScoresWrapper>
          <ScoreWrapper
            style={{
              fontWeight:
                (getScoreWithId(game.id)[1] ?? 0) > (getScoreWithId(game.id)[0] ?? 0)
                  ? '600'
                  : '300',
            }}
          >
            {(getScoreWithId(game.id)[1] ?? 0).toLocaleString()}
          </ScoreWrapper>
        </ScoresWrapper>
        <TeamWrapper>
          <TeamEmojiPlayer>{game.teams[1]?.players[0]?.emoji}</TeamEmojiPlayer>
          <TeamEmojiPlayer>{game.teams[1]?.players[1]?.emoji}</TeamEmojiPlayer>
        </TeamWrapper>
      </ContentWrapper>
      <ButtonWrapper>
        <CustomButton
          iconSizeRatio={1.2}
          icon={trashCanOutlineIcon}
          size="medium"
          onPress={() => onPressDeletePlayer()}
        />
      </ButtonWrapper>
    </GlobalWrapper>
  );
};

SelectableGame.displayName = 'SelectableGame';

//______________ CSS ______________

//______________ WRAPPER ______________
const GlobalWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: ${spacing}px;
  border-radius: ${borderRadius * 2}px;
  background-color: ${pastilleBackgroundColor};
`;

const ContentWrapper = styled(UnthemedButton)`
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  background-color: white;
  margin-right: ${spacing}px;
  border-radius: ${borderRadius * 2}px;
`;

const ButtonWrapper = styled.div`
  flex-shrink: 0;
`;

//______________ TEAM ______________
const TeamWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-content: center;
`;
const TeamEmojiPlayer = styled.span`
  font-size: ${fontSizes.extraLarge}px;
  height: ${buttonHeight.medium}px;
  width: ${buttonHeight.medium}px;
  text-align: center;
  line-height: ${buttonHeight.medium}px;
`;

//______________ SCORE ______________
const ScoresWrapper = styled.div`
  flex-direction: column;
  background-color: ${white};
  align-items: center;
  width: 0;
  flex-grow: 1;
`;

const ScoreWrapper = styled.span`
  font-size: ${fontSizes.large}px;
  height: ${buttonHeight.medium}px;
  flex-grow: 1;
  text-align: center;
  line-height: ${buttonHeight.medium}px;
  color: ${black};
`;
