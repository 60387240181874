import {FC, useCallback, useEffect, useRef, useState} from 'react';
import {styled} from 'styled-components';
import {useLocation} from 'wouter';

import {shuffleArray} from '@shared/lib/random_utils';

import {arrowLeftIcon} from '@shared-web/components/icons/mui/arrow_left_icon';
import {useStateRef} from '@shared-web/lib/use_state_ref';

import {BottomBar} from '@src/components/bottom_bar';
import {CustomButton} from '@src/components/custom_buttons';
import {TopBar} from '@src/components/top_bar';
import card_7_carreau from '@src/images/7_carreau.png';
import card_7_coeur from '@src/images/7_coeur.png';
import card_7_pique from '@src/images/7_pique.png';
import card_7_trèfle from '@src/images/7_trèfle.png';
import card_8_carreau from '@src/images/8_carreau.png';
import card_8_coeur from '@src/images/8_coeur.png';
import card_8_pique from '@src/images/8_pique.png';
import card_8_trèfle from '@src/images/8_trèfle.png';
import card_9_carreau from '@src/images/9_carreau.png';
import card_9_coeur from '@src/images/9_coeur.png';
import card_9_pique from '@src/images/9_pique.png';
import card_9_trèfle from '@src/images/9_trèfle.png';
import card_10_carreau from '@src/images/10_carreau.png';
import card_10_coeur from '@src/images/10_coeur.png';
import card_10_pique from '@src/images/10_pique.png';
import card_10_trèfle from '@src/images/10_trèfle.png';
import card_As_carreau from '@src/images/As_carreau.png';
import card_As_coeur from '@src/images/As_coeur.png';
import card_As_pique from '@src/images/As_pique.png';
import card_As_trèfle from '@src/images/As_trèfle.png';
import card_D_carreau from '@src/images/D_carreau.png';
import card_D_coeur from '@src/images/D_coeur.png';
import card_D_pique from '@src/images/D_pique.png';
import card_D_trèfle from '@src/images/D_trèfle.png';
import card_Joker from '@src/images/Joker.png';
import card_R_carreau from '@src/images/R_carreau.png';
import card_R_coeur from '@src/images/R_coeur.png';
import card_R_pique from '@src/images/R_pique.png';
import card_R_trèfle from '@src/images/R_trèfle.png';
import card_V_carreau from '@src/images/V_carreau.png';
import card_V_coeur from '@src/images/V_coeur.png';
import card_V_pique from '@src/images/V_pique.png';
import card_V_trèfle from '@src/images/V_trèfle.png';
import {fontSizes, spacing, topBarButtonWidth, topBarColor} from '@src/lib/theme';

interface Card {
  value: '7' | '8' | '9' | '10' | 'V' | 'D' | 'R' | 'As' | 'J';
  symbol: 'coeur' | 'carreau' | 'trèfle' | 'pique' | 'J';
  img: string;
}

type CardPosition = 'center' | 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right';

const jokerCard: Card = {value: 'J', symbol: 'J', img: card_Joker};

const allCards = [
  {value: '7', symbol: 'coeur', img: card_7_coeur},
  {value: '8', symbol: 'coeur', img: card_8_coeur},
  {value: '9', symbol: 'coeur', img: card_9_coeur},
  {value: '10', symbol: 'coeur', img: card_10_coeur},
  {value: 'V', symbol: 'coeur', img: card_V_coeur},
  {value: 'D', symbol: 'coeur', img: card_D_coeur},
  {value: 'R', symbol: 'coeur', img: card_R_coeur},
  {value: 'As', symbol: 'coeur', img: card_As_coeur},
  {value: '7', symbol: 'carreau', img: card_7_carreau},
  {value: '8', symbol: 'carreau', img: card_8_carreau},
  {value: '9', symbol: 'carreau', img: card_9_carreau},
  {value: '10', symbol: 'carreau', img: card_10_carreau},
  {value: 'V', symbol: 'carreau', img: card_V_carreau},
  {value: 'D', symbol: 'carreau', img: card_D_carreau},
  {value: 'R', symbol: 'carreau', img: card_R_carreau},
  {value: 'As', symbol: 'carreau', img: card_As_carreau},
  {value: '7', symbol: 'trèfle', img: card_7_trèfle},
  {value: '8', symbol: 'trèfle', img: card_8_trèfle},
  {value: '9', symbol: 'trèfle', img: card_9_trèfle},
  {value: '10', symbol: 'trèfle', img: card_10_trèfle},
  {value: 'V', symbol: 'trèfle', img: card_V_trèfle},
  {value: 'D', symbol: 'trèfle', img: card_D_trèfle},
  {value: 'R', symbol: 'trèfle', img: card_R_trèfle},
  {value: 'As', symbol: 'trèfle', img: card_As_trèfle},
  {value: '7', symbol: 'pique', img: card_7_pique},
  {value: '8', symbol: 'pique', img: card_8_pique},
  {value: '9', symbol: 'pique', img: card_9_pique},
  {value: '10', symbol: 'pique', img: card_10_pique},
  {value: 'V', symbol: 'pique', img: card_V_pique},
  {value: 'D', symbol: 'pique', img: card_D_pique},
  {value: 'R', symbol: 'pique', img: card_R_pique},
  {value: 'As', symbol: 'pique', img: card_As_pique},
];

const SLOW = 800;
const NORMAL = 400;
const FAST = 200;

export const KingsPull: FC = () => {
  const setLocation = useLocation()[1];

  // Speed of the drawing
  const [speed, setSpeed, speedRef] = useStateRef(NORMAL);
  const handleSlowClick = useCallback(() => setSpeed(SLOW), [setSpeed]);
  const handleNormalClick = useCallback(() => setSpeed(NORMAL), [setSpeed]);
  const handleFastClick = useCallback(() => setSpeed(FAST), [setSpeed]);

  // Track the size of the board
  const boardRef = useRef<HTMLDivElement>(null);
  const [boardSize, setBoardSize] = useState<{width: number; height: number}>();
  const handleBoardResize = useCallback(() => {
    if (!boardRef.current) {
      return;
    }
    const {width, height} = boardRef.current.getBoundingClientRect();
    setBoardSize({width, height});
  }, []);
  useEffect(handleBoardResize, [handleBoardResize]);
  useEffect(() => {
    window.addEventListener('resize', handleBoardResize);
    return () => window.removeEventListener('resize', handleBoardResize);
  }, [handleBoardResize]);

  // Track the state of the cards
  const [deck, setDeck, deckRef] = useStateRef(
    shuffleArray(allCards.map(c => ({...c, position: 'center' as CardPosition, flipped: false})))
  );

  // Schedule card state update
  const [, setCanStart, canStartRef] = useStateRef(true);
  const start = useCallback(() => {
    if (!canStartRef.current) {
      return;
    }
    setDeck(
      shuffleArray(allCards.map(c => ({...c, position: 'center' as CardPosition, flipped: false})))
    );
    setCanStart(false);
    let redKingCount = 0;
    let blackKingCount = 0;
    const POSITIONS: CardPosition[] = ['top-left', 'top-right', 'bottom-right', 'bottom-left'];
    let currentPos = 0;
    for (const [i, card] of deckRef.current.entries()) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const position = POSITIONS[currentPos % POSITIONS.length]!;
      if (card.value === 'R') {
        if (card.symbol === 'carreau' || card.symbol === 'coeur') {
          redKingCount++;
        } else {
          blackKingCount++;
        }
        POSITIONS.splice(currentPos, 1);
      } else {
        currentPos = (currentPos + 1) % POSITIONS.length;
      }
      const isLast = redKingCount === 2 || blackKingCount === 2;
      setTimeout(() => {
        setDeck(deck => {
          const newDeck = [...deck];
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          newDeck[i] = {...newDeck[i]!, flipped: true, position};
          return newDeck;
        });
        if (isLast) {
          setCanStart(true);
        }
      }, i * speedRef.current);

      if (isLast) {
        break;
      }
    }
  }, [canStartRef, deckRef, setCanStart, setDeck, speedRef]);

  return (
    <>
      <TopBar
        middle={<Titre>{`Tirage`}</Titre>}
        left={
          <CustomButton
            text="Retour"
            icon={arrowLeftIcon}
            onPress={() => setLocation('accueil')}
            width={topBarButtonWidth}
          />
        }
      />
      <SpeedButtons>
        <CustomButton
          style={speed === SLOW ? undefined : {backgroundColor: '#fff'}}
          text="LENT"
          onPress={handleSlowClick}
        />
        <CustomButton
          style={speed === NORMAL ? undefined : {backgroundColor: '#fff'}}
          text="MOYEN"
          onPress={handleNormalClick}
        />
        <CustomButton
          style={speed === FAST ? undefined : {backgroundColor: '#fff'}}
          text="RAPIDE"
          onPress={handleFastClick}
        />
      </SpeedButtons>
      <Board ref={boardRef} onClick={start}>
        {boardSize === undefined ? (
          <></>
        ) : (
          [...deck].reverse().map((card, i) => (
            <CardWrapper
              key={card.img}
              $position={card.position}
              $boardWidth={boardSize.width}
              $boardHeight={boardSize.height}
              style={{zIndex: card.flipped ? 1000 - i : 1}}
            >
              <CardInner $isFlipped={card.flipped}>
                <CardFront>
                  <CardImg src={jokerCard.img} />
                </CardFront>
                <CardBack>
                  <CardImg src={card.img} />
                </CardBack>
              </CardInner>
            </CardWrapper>
          ))
        )}
      </Board>
      <BottomBar />
    </>
  );
};

KingsPull.displayName = 'KingsPull';

const Titre = styled.span`
  font-size: ${fontSizes.medium}px;
  flex-grow: 1;
  text-align: center;
  color: ${topBarColor};
  margin-right: ${topBarButtonWidth}px;
`;

// const WrapperCard = styled.div`
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   margin: ${spacing}px;
//   margin-top: 0;
// `;

const CARD_WIDTH = 125;
const CARD_HEIGHT = 181.5;
const TRANSITION_DURATION = '500ms';

function getPositionCss(position: CardPosition, boardWidth: number, boardHeight: number): string {
  const QUARTER_CIRCLE = 90;
  const HALF_CIRCLE = 180;
  const baseAngle = Math.atan2(boardWidth, boardHeight);
  const baseAngleDeg = (HALF_CIRCLE * baseAngle) / Math.PI;

  const offsetX = Math.cos(baseAngle) * boardWidth * 0.1; // eslint-disable-line @typescript-eslint/no-magic-numbers
  const offsetY = Math.sin(baseAngle) * boardWidth * 0.1; // eslint-disable-line @typescript-eslint/no-magic-numbers

  const left = offsetX;
  const top = offsetY;
  const right = boardWidth - CARD_WIDTH - offsetX;
  const bottom = boardHeight - CARD_HEIGHT - offsetY;
  const middleLeft = (boardWidth - CARD_WIDTH) / 2;
  const middleTop = (boardHeight - CARD_HEIGHT) / 2;

  const POSITIONS: Record<CardPosition, string> = {
    center: `
      top: ${middleTop}px;
      left: ${middleLeft}px;
      transform: rotate(0);
      `,
    'top-left': `
      top: ${top}px;
      left: ${left}px;
      transform: rotate(${QUARTER_CIRCLE + QUARTER_CIRCLE - baseAngleDeg}deg);
      `,
    'top-right': `
      top: ${top}px;
      left: ${right}px;
      transform: rotate(${baseAngleDeg}deg);
      `,
    'bottom-left': `
      top: ${bottom}px;
      left: ${left};
      transform: rotate(${baseAngleDeg - QUARTER_CIRCLE - QUARTER_CIRCLE}deg);
      `,
    'bottom-right': `
      top: ${bottom}px;
      left: ${right}px;
      transform: rotate(${-baseAngleDeg}deg);
    `,
  };

  return POSITIONS[position];
}

const Board = styled.div`
  position: relative;
  background-color: green;
  width: calc(100% - ${2 * spacing}px);
  height: calc(100% - ${2 * spacing}px);
  margin: 0 ${spacing}px;
`;

const CardWrapper = styled.div<{
  $position: CardPosition;
  $boardWidth: number;
  $boardHeight: number;
}>`
  position: absolute;
  ${p => getPositionCss(p.$position, p.$boardWidth, p.$boardHeight)}
  transition-property: transform, left, top;
  transition-duration: ${TRANSITION_DURATION};
  transition-timing-function: linear;
  width: ${CARD_WIDTH}px;
  height: ${CARD_HEIGHT}px;
  perspective: 1000px;
`;
const CardInner = styled.div<{$isFlipped: boolean}>`
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition-property: transform;
  transition-duration: ${TRANSITION_DURATION};
  transition-timing-function: linear;
  transform-style: preserve-3d;
  ${p => p.$isFlipped && 'transform: rotateY(180deg);'}
`;
const CardBase = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
`;
const CardFront = styled(CardBase)``;
const CardBack = styled(CardBase)`
  transform: rotateY(180deg);
`;
const CardImg = styled.img`
  width: 100%;
  height: 100%;
`;

const SpeedButtons = styled.div`
  display: flex;
  gap: 16px;
  padding: 0 ${spacing}px ${spacing}px ${spacing}px;
`;
