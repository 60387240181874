import {FC, useState} from 'react';
import {styled} from 'styled-components';
import {useLocation} from 'wouter';

import {arrowLeftIcon} from '@shared-web/components/icons/mui/arrow_left_icon';
import {diceIcon} from '@shared-web/components/icons/mui/dice_icon';
import {playingCardsIcon} from '@shared-web/components/icons/mui/playing_cards_icon';
import {
  useBooleanQueryString,
  useNumberQueryString,
  useStringArrayQueryString,
} from '@shared-web/lib/use_query_string';

import {BottomBar} from '@src/components/bottom_bar';
import {CustomButton} from '@src/components/custom_buttons';
import {VerticalSpacing} from '@src/components/spacing';
import {TopBar} from '@src/components/top_bar';
import {getNewGame, setGame} from '@src/lib/stores/games_store';
import {usePlayers} from '@src/lib/stores/players_store';
import {
  borderRadius,
  buttonHeight,
  fontSizes,
  inputBackgroundColor,
  pastilleBackgroundColor,
  primary,
  spacing,
  topBarButtonWidth,
  topBarColor,
  white,
} from '@src/lib/theme';
import {getRandomTeams} from '@src/lib/utilities';

export const Tirage: FC = () => {
  const setLocation = useLocation()[1];

  const [allPlayers] = usePlayers();
  const [numTeams] = useNumberQueryString('teams', 2);
  const [playerIdsStr] = useStringArrayQueryString('players', []);
  const [coincheMode] = useBooleanQueryString('coinche', false);
  const playerIds = new Set(playerIdsStr.map(id => parseFloat(id)));
  const players = allPlayers.filter(p => playerIds.has(p.id));

  const [teams, setTeams] = useState(getRandomTeams(numTeams, players));

  const handlePressTirage = (): void => {
    setTeams(getRandomTeams(numTeams, players));
  };

  const handlePressStart = (): void => {
    const new_game = getNewGame(teams);
    setGame(new_game);
    setLocation(`/game/${new_game.id}`);
  };

  const scrollViewContent: JSX.Element[] = [];
  let index = 1;
  for (const [i, team] of teams.entries()) {
    if (i !== 0) {
      scrollViewContent.push(<VerticalSpacing key={`spacing-${team.id}`} height={spacing * 2} />);
    }
    scrollViewContent.push(
      <WrapperTeamText key={team.id}>
        <TeamText>{`Equipe ${[index]}`}</TeamText>
      </WrapperTeamText>
    );
    for (const p of team.players) {
      scrollViewContent.push(
        <VerticalSpacing key={`spacing-${p.id}`} height={spacing} />,
        <PlayerWrapper key={p.id}>
          <PlayerEmoji>{p.emoji}</PlayerEmoji>
          <PlayerText>{p.name}</PlayerText>
        </PlayerWrapper>
      );
    }
    index++;
  }

  return (
    <>
      <TopBar
        middle={<Titre>{`Tirage`}</Titre>}
        left={
          <CustomButton
            text="Retour"
            icon={arrowLeftIcon}
            onPress={() => setLocation('/selection')}
            width={topBarButtonWidth}
          />
        }
      />
      <StyledScrollView>{scrollViewContent}</StyledScrollView>
      {coincheMode ? (
        <>
          <WrapperBottomButton>
            <CustomButton
              text="Lancer la partie"
              icon={playingCardsIcon}
              size="large"
              onPress={handlePressStart}
            />
          </WrapperBottomButton>
          <VerticalSpacing height={spacing} />
        </>
      ) : (
        <></>
      )}
      <WrapperBottomButton>
        <CustomButton
          text="Relancer le tirage"
          icon={diceIcon}
          size="large"
          onPress={() => handlePressTirage()}
        />
      </WrapperBottomButton>

      <BottomBar />
    </>
  );
};
Tirage.displayName = 'Tirage';

const Titre = styled.span`
  font-size: ${fontSizes.medium}px;
  flex-grow: 1;
  text-align: center;
  color: ${topBarColor};
  margin-right: ${topBarButtonWidth}px;
`;

const PlayerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: ${spacing}px;
  border-radius: ${borderRadius * 2}px;
  background-color: ${pastilleBackgroundColor};
`;

const PlayerEmoji = styled.span`
  flex-shrink: 0;
  background-color: ${inputBackgroundColor};
  font-size: ${fontSizes.medium}px;
  height: ${buttonHeight.medium}px;
  width: ${buttonHeight.medium}px;
  text-align: center;
  line-height: ${buttonHeight.medium}px;
`;

const PlayerText = styled.span`
  flex-grow: 1;
  background-color: ${inputBackgroundColor};
  font-size: ${fontSizes.medium}px;
  height: ${buttonHeight.medium}px;
  width: ${buttonHeight.medium}px;
  text-align: left;
  line-height: ${buttonHeight.medium}px;
`;

const WrapperTeamText = styled.div`
  flex-grow: 1;
  border-radius: ${borderRadius * 2}px;
  line-height: ${buttonHeight.medium}px;
  font-size: ${fontSizes.medium}px;
  height: ${buttonHeight.medium}px;
  padding-left: ${spacing}px;
  padding-right: ${spacing}px;
  background-color: ${primary};
`;

const TeamText = styled.span`
  flex-shrink: 1;
  line-height: ${buttonHeight.medium}px;
  font-size: ${fontSizes.medium}px;
  color: ${white};
`;

const StyledScrollView = styled.div`
  flex-grow: 1;
  margin: ${spacing}px;
  margin-top: 0;
  overflow-y: auto;
`;

const WrapperBottomButton = styled.div`
  margin: ${spacing}px;
  margin-top: 0;
`;
