import {FC, useEffect, useState} from 'react';
import {styled} from 'styled-components';
import {useLocation, useParams} from 'wouter';

import {arrowLeftIcon} from '@shared-web/components/icons/mui/arrow_left_icon';
import {contentSaveIcon} from '@shared-web/components/icons/mui/content_save_icon';

import {BottomBar} from '@src/components/bottom_bar';
import {CustomButton} from '@src/components/custom_buttons';
import {TopBar} from '@src/components/top_bar';
import {addRound, getGameWithId, getInitialRound} from '@src/lib/stores/games_store';
import {
  black,
  borderRadius,
  buttonHeight,
  fontSizes,
  green,
  pastilleBackgroundColor,
  red,
  secondary,
  spacing,
  topBarButtonWidth,
  topBarColor,
  white,
} from '@src/lib/theme';

export const Round: FC = () => {
  //______________ STORE & STATE ______________
  const gameId = parseFloat(useParams()['gameId'] ?? '');
  const hand = parseFloat(useParams()['hand'] ?? '');
  const setLocation = useLocation()[1];
  const [round, setRound] = useState(getInitialRound(gameId, hand));

  //______________ FUNCTIONS ______________
  useEffect(() => {
    if (isNaN(gameId)) {
      setLocation(`/accueil`);
    }
  }, [gameId, setLocation]);

  const handlePressTeam = (index_team: number): void => {
    if (!round) {
      return;
    }
    if (round.taker_team_index === index_team) {
      setRound({...round, taker_team_index: undefined});
    } else {
      setRound({...round, taker_team_index: index_team});
    }
  };

  const handlePressCoinche = (): void => {
    if (!round) {
      return;
    }
    setRound({...round, coinche: !round.coinche});
  };

  const handlePressSurcoinche = (): void => {
    if (!round) {
      return;
    }
    setRound({...round, surcoinche: !round.surcoinche});
  };

  const handlePressRealise = (): void => {
    if (!round) {
      return;
    }
    if (round.successful === 'oui') {
      setRound({...round, successful: '?'});
    } else {
      setRound({...round, successful: 'oui'});
    }
  };

  const handlePressChute = (): void => {
    if (!round) {
      return;
    }
    if (round.successful === 'non') {
      setRound({...round, successful: '?'});
    } else {
      setRound({...round, successful: 'non'});
    }
  };

  const handlePressValue = (value: number): void => {
    if (!round) {
      return;
    }
    if (round.annonce === value) {
      setRound({...round, annonce: 0});
    } else {
      setRound({...round, annonce: value});
    }
  };

  const handlePressSave = (): void => {
    addRound(round, gameId);
    setLocation(`/game/${gameId}`);
  };

  const isValidRound = (): boolean => {
    if (
      round?.annonce !== undefined &&
      round.taker_team_index !== undefined &&
      round.successful !== '?'
    ) {
      return false;
    }
    return true;
  };

  //______________ INIT ______________
  return (
    <GlobalWrapper>
      <TopBar
        left={
          <CustomButton
            text="Retour"
            icon={arrowLeftIcon}
            onPress={() => setLocation(`/game/${gameId}`)}
            width={topBarButtonWidth}
          />
        }
        middle={<Titre>Mène</Titre>}
      />
      <WrapperContent>
        <Container>
          <Question>Quelle est l’équipe preneuse ?</Question>
          <FakeButtons>
            <FakeButton onClick={() => handlePressTeam(0)}>
              <ButtonWrapper
                style={{backgroundColor: round?.taker_team_index === 0 ? secondary : white}}
              >
                <TeamEmojiPlayer>
                  {getGameWithId(gameId)[0]?.teams[0]?.players[0]?.emoji}
                </TeamEmojiPlayer>
                <TeamEmojiPlayer>
                  {getGameWithId(gameId)[0]?.teams[0]?.players[1]?.emoji}
                </TeamEmojiPlayer>
              </ButtonWrapper>
            </FakeButton>
            <FakeButton onClick={() => handlePressTeam(1)}>
              <ButtonWrapper
                style={{backgroundColor: round?.taker_team_index === 1 ? secondary : white}}
              >
                <TeamEmojiPlayer>
                  {getGameWithId(gameId)[0]?.teams[1]?.players[0]?.emoji}
                </TeamEmojiPlayer>
                <TeamEmojiPlayer>
                  {getGameWithId(gameId)[0]?.teams[1]?.players[1]?.emoji}
                </TeamEmojiPlayer>
              </ButtonWrapper>
            </FakeButton>
          </FakeButtons>
        </Container>
        <Container>
          <Question>Quelle est la valeur du contrat ?</Question>
          <FakeButtons>
            {/* eslint-disable @typescript-eslint/no-magic-numbers */}
            <FakeButton onClick={() => handlePressValue(80)}>
              <ButtonWrapper style={{backgroundColor: round?.annonce === 80 ? secondary : white}}>
                <ButtonText>80</ButtonText>
              </ButtonWrapper>
            </FakeButton>
            <FakeButton onClick={() => handlePressValue(90)}>
              <ButtonWrapper style={{backgroundColor: round?.annonce === 90 ? secondary : white}}>
                <ButtonText>90</ButtonText>
              </ButtonWrapper>
            </FakeButton>
            <FakeButton onClick={() => handlePressValue(100)}>
              <ButtonWrapper style={{backgroundColor: round?.annonce === 100 ? secondary : white}}>
                <ButtonText>100</ButtonText>
              </ButtonWrapper>
            </FakeButton>
          </FakeButtons>
          <FakeButtons>
            <FakeButton onClick={() => handlePressValue(110)}>
              <ButtonWrapper style={{backgroundColor: round?.annonce === 110 ? secondary : white}}>
                <ButtonText>110</ButtonText>
              </ButtonWrapper>
            </FakeButton>
            <FakeButton onClick={() => handlePressValue(120)}>
              <ButtonWrapper style={{backgroundColor: round?.annonce === 120 ? secondary : white}}>
                <ButtonText>120</ButtonText>
              </ButtonWrapper>
            </FakeButton>
            <FakeButton onClick={() => handlePressValue(130)}>
              <ButtonWrapper style={{backgroundColor: round?.annonce === 130 ? secondary : white}}>
                <ButtonText>130</ButtonText>
              </ButtonWrapper>
            </FakeButton>
          </FakeButtons>
          <FakeButtons>
            <FakeButton onClick={() => handlePressValue(140)}>
              <ButtonWrapper style={{backgroundColor: round?.annonce === 140 ? secondary : white}}>
                <ButtonText>140</ButtonText>
              </ButtonWrapper>
            </FakeButton>
            <FakeButton onClick={() => handlePressValue(150)}>
              <ButtonWrapper style={{backgroundColor: round?.annonce === 150 ? secondary : white}}>
                <ButtonText>150</ButtonText>
              </ButtonWrapper>
            </FakeButton>
            <FakeButton onClick={() => handlePressValue(160)}>
              <ButtonWrapper style={{backgroundColor: round?.annonce === 160 ? secondary : white}}>
                <ButtonText>160</ButtonText>
              </ButtonWrapper>
            </FakeButton>
          </FakeButtons>
          <FakeButtons>
            <FakeButton onClick={() => handlePressValue(250)}>
              <ButtonWrapper style={{backgroundColor: round?.annonce === 250 ? secondary : white}}>
                <ButtonText>CAPOT</ButtonText>
              </ButtonWrapper>
            </FakeButton>
            <FakeButton onClick={() => handlePressValue(500)}>
              <ButtonWrapper style={{backgroundColor: round?.annonce === 500 ? secondary : white}}>
                <ButtonText>GÉNÉRALE</ButtonText>
              </ButtonWrapper>
            </FakeButton>
          </FakeButtons>
          {/* eslint-enable @typescript-eslint/no-magic-numbers */}
        </Container>
        <Container>
          <Question>Le contrat a-t-il été contré ?</Question>
          <FakeButtons>
            <FakeButton onClick={handlePressCoinche}>
              <ButtonWrapper style={{backgroundColor: round?.coinche ? secondary : white}}>
                <ButtonText>COINCHÉ</ButtonText>
              </ButtonWrapper>
            </FakeButton>
            <FakeButton onClick={handlePressSurcoinche}>
              <ButtonWrapper style={{backgroundColor: round?.surcoinche ? secondary : white}}>
                <ButtonText>SURCOINCHÉ</ButtonText>
              </ButtonWrapper>
            </FakeButton>
          </FakeButtons>
        </Container>
        <Container>
          <Question>Le contrat a-t-il été réalisé ?</Question>
          <FakeButtons>
            <FakeButton onClick={handlePressRealise}>
              <ButtonWrapper style={{backgroundColor: round?.successful === 'oui' ? green : white}}>
                <ButtonText
                  style={{
                    color: round?.successful === 'oui' ? white : black,
                  }}
                >
                  REALISÉ
                </ButtonText>
              </ButtonWrapper>
            </FakeButton>
            <FakeButton onClick={handlePressChute}>
              <ButtonWrapper
                style={{
                  backgroundColor: round?.successful === 'non' ? red : white,
                }}
              >
                <ButtonText
                  style={{
                    color: round?.successful === 'non' ? white : black,
                  }}
                >
                  CHUTÉ
                </ButtonText>
              </ButtonWrapper>
            </FakeButton>
          </FakeButtons>
        </Container>
      </WrapperContent>
      <WrapperAdd>
        <CustomButton
          text="Enregistrer la mène"
          size="large"
          icon={contentSaveIcon}
          onPress={handlePressSave}
          disabled={isValidRound()}
        />
      </WrapperAdd>
      <BottomBar />
    </GlobalWrapper>
  );
};

Round.displayName = 'Round';

//______________ CSS ______________

//______________ TOPBAR ______________
const Titre = styled.span`
  font-size: ${fontSizes.medium}px;
  flex-grow: 1;
  text-align: center;
  color: ${topBarColor};
  margin-right: ${topBarButtonWidth}px;
`;

//______________ WRAPPER ______________
const GlobalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
`;

const WrapperContent = styled.div`
  display: flex;
  flex-direction: column;
`;

const WrapperAdd = styled.div`
  margin: 0 ${spacing}px;
  flex-shrink: 0;
`;

//______________ BLOC SELECTION ______________

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background-color: ${pastilleBackgroundColor};
  border-radius: ${borderRadius * 2}px;
  margin: ${spacing}px;
  margin-top: 0;
  overflow: hidden;
`;

const Question = styled.span`
  flex-grow: 1;
  font-size: ${fontSizes.medium}px;
  height: ${buttonHeight.medium}px;
  text-align: center;
  line-height: ${buttonHeight.medium}px;
`;

const FakeButtons = styled.div`
  display: flex;
  flex-direction: row;
  align-content: center;
  padding-left: ${spacing / 2}px;
  padding-right: ${spacing / 2}px;
`;

const FakeButton = styled.button`
  flex-grow: 1;
  padding-top: 0;
  padding-bottom: ${spacing}px;
  padding-left: ${spacing / 2}px;
  padding-right: ${spacing / 2}px;
`;

const ButtonWrapper = styled.div`
  border-radius: ${borderRadius * 2}px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  justify-content: center;
`;

const TeamEmojiPlayer = styled.span`
  font-size: ${fontSizes.extraLarge}px;
  height: ${buttonHeight.medium}px;
  width: ${buttonHeight.medium}px;
  text-align: center;
  line-height: ${buttonHeight.medium}px;
`;

const ButtonText = styled.span`
  font-size: ${fontSizes.medium}px;
  height: ${buttonHeight.medium}px;
  width: 0;
  flex-grow: 1;
  text-align: center;
  line-height: ${buttonHeight.medium}px;
`;
