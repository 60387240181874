import {FC} from 'react';
import {styled} from 'styled-components';
import {useLocation} from 'wouter';

import {arrowLeftIcon} from '@shared-web/components/icons/mui/arrow_left_icon';
import {playingCardsIcon} from '@shared-web/components/icons/mui/playing_cards_icon';

import {BottomBar} from '@src/components/bottom_bar';
import {CustomButton} from '@src/components/custom_buttons';
import {SelectableGame} from '@src/components/selectable_game';
import {VerticalSpacing} from '@src/components/spacing';
import {TopBar} from '@src/components/top_bar';
import {useGames} from '@src/lib/stores/games_store';
import {fontSizes, spacing, topBarButtonWidth, topBarColor} from '@src/lib/theme';

export const GamesSelection: FC = () => {
  const setLocation = useLocation()[1];
  const [games] = useGames();

  const handleAddGamePress = (): void => {
    setLocation('/selection?coinche=1');
  };

  const scrollViewContent: JSX.Element[] = [];
  if (games.length > 0) {
    let firstPlayer = true;
    for (const game of [...games].reverse()) {
      if (firstPlayer) {
        firstPlayer = false;
      } else {
        scrollViewContent.push(
          <VerticalSpacing key={`spacing_game_${game.id}`} height={spacing} />
        );
      }
      scrollViewContent.push(<SelectableGame game={game} key={game.id} />);
    }
  }
  return (
    <>
      <TopBar
        left={
          <CustomButton
            text="Retour"
            icon={arrowLeftIcon}
            onPress={() => setLocation('/accueil')}
            width={topBarButtonWidth}
          />
        }
        middle={<Titre>Parties</Titre>}
      />
      <WrapperAdd>
        <CustomButton
          text="Démarrer une nouvelle partie"
          size="large"
          icon={playingCardsIcon}
          onPress={() => handleAddGamePress()}
        />
      </WrapperAdd>
      <VerticalSpacing key={'spacing_game_scrollView'} height={spacing} />
      <StyledScrollView
      // showsHorizontalScrollIndicator={false}
      //  showsVerticalScrollIndicator={false}
      >
        {scrollViewContent}
      </StyledScrollView>
      <BottomBar />
    </>
  );
};
GamesSelection.displayName = 'GamesSelection';

const Titre = styled.span`
  font-size: ${fontSizes.medium}px;
  flex-grow: 1;
  text-align: center;
  color: ${topBarColor};
  margin-right: ${topBarButtonWidth}px;
`;

const WrapperAdd = styled.div`
  margin: 0 ${spacing}px;
  flex-shrink: 0;
`;

const StyledScrollView = styled.div`
  flex-grow: 1;
  margin: ${spacing}px;
  margin-top: 0;
  overflow-y: auto;
`;
