import {FC} from 'react';
import {styled} from 'styled-components';
import {useLocation, useParams} from 'wouter';

import {arrowLeftIcon} from '@shared-web/components/icons/mui/arrow_left_icon';
import {backspaceOutlineIcon} from '@shared-web/components/icons/mui/backspace_outline_icon';
import {plusCircleOutlineIcon} from '@shared-web/components/icons/mui/plus_circle_outline_icon';

import {BottomBar} from '@src/components/bottom_bar';
import {CustomButton} from '@src/components/custom_buttons';
import {HorizontalSpacing, VerticalSpacing} from '@src/components/spacing';
import {TopBar} from '@src/components/top_bar';
import {delRound, getGameWithId, useGames} from '@src/lib/stores/games_store';
import {
  black,
  borderColorGame,
  borderRadius,
  buttonHeight,
  darkgray,
  fontSizes,
  gray,
  pLight,
  pVeryLight,
  spacing,
  topBarButtonWidth,
  topBarColor,
  white,
} from '@src/lib/theme';
import {getScoreWithId, getscoreWithRound} from '@src/lib/utilities';

export const Game: FC = () => {
  //______________ STORE & STATE ______________
  const setLocation = useLocation()[1];
  const gameId = parseFloat(useParams()['gameId'] ?? '');
  const [games] = useGames();
  // const scrollViewRef = useRef<ScrollView | null>();

  const handlePressTeam = (i: number): void => {
    setLocation(`/game/${gameId}/round/${i}`);
  };
  const handlePressAnnuler = (): void => {
    // eslint-disable-next-line no-restricted-globals
    const choice = confirm(`Voulez-vous supprimer la dernière mène ?`);
    if (choice) {
      delRound(gameId);
    }
  };
  //______________ INIT ______________
  if (getGameWithId(gameId).length === 0) {
    return <></>;
  }
  const scrollViewContent: JSX.Element[] = [];
  const game = games.find(game => game.id === gameId);
  if (!game) {
    setLocation(`/accueil`);
    return <></>;
  }

  let i = 0;
  const current_score: [number, number] = [0, 0];
  for (const r of game.rounds) {
    const current_round_score = getscoreWithRound(r);
    current_score[0] += current_round_score[0];
    current_score[1] += current_round_score[1];
    scrollViewContent.push(
      <WrapperLineScore key={r.id}>
        <LineScoreTotal0
          key={`LineScoreTotal0${r.id}`}
          style={{
            backgroundColor: i % 2 === 0 ? pVeryLight : white,
            fontWeight: current_score[0] > current_score[1] ? '600' : '300',
          }}
        >
          {current_score[0].toLocaleString()}
        </LineScoreTotal0>
        <LineScore0
          key={`LineScore0${r.id}`}
          style={{backgroundColor: i % 2 === 0 ? pVeryLight : white}}
        >
          {current_round_score[0] > 0 ? `+ ${current_round_score[0]}` : `-`}
        </LineScore0>
        <LineIndexMene
          key={`index${r.id}`}
          style={{backgroundColor: i % 2 === 0 ? pVeryLight : white}}
        >
          {i + 1}
        </LineIndexMene>
        <LineScore1
          key={`LineScore1${r.id}`}
          style={{backgroundColor: i % 2 === 0 ? pVeryLight : white}}
        >
          {current_round_score[1] > 0 ? `+ ${current_round_score[1]}` : `-`}
        </LineScore1>
        <LineScoreTotal1
          key={`LineScoreTotal1${r.id}`}
          style={{
            backgroundColor: i % 2 === 0 ? pVeryLight : white,
            fontWeight: current_score[1] > current_score[0] ? '600' : '300',
          }}
        >
          {current_score[1].toLocaleString()}
        </LineScoreTotal1>
      </WrapperLineScore>
    );
    i++;
  }

  //______________ HTML ______________
  return (
    <GlobalWrapper>
      <TopBar
        left={
          <CustomButton
            text="Retour"
            icon={arrowLeftIcon}
            onPress={() => setLocation(`/games-selection`)}
            width={topBarButtonWidth}
          />
        }
        middle={<Titre>Partie</Titre>}
        right={
          <CustomButton
            text="Annuler"
            icon={backspaceOutlineIcon}
            onPress={handlePressAnnuler}
            width={topBarButtonWidth}
            disabled={!((getGameWithId(gameId)[0]?.rounds.length ?? 0) > 0)}
          />
        }
      />
      <WrapperContent>
        <LineTeamsWrapper>
          <TeamsWrapper onClick={() => handlePressTeam(0)}>
            <TeamWrapper>
              <TeamEmojiPlayer>
                {getGameWithId(gameId)[0]?.teams[0]?.players[0]?.emoji}
              </TeamEmojiPlayer>
              <TeamEmojiPlayer>
                {getGameWithId(gameId)[0]?.teams[0]?.players[1]?.emoji}
              </TeamEmojiPlayer>
            </TeamWrapper>
            <ScoreWrapper
              style={{
                fontWeight:
                  (getScoreWithId(gameId)[0] ?? 0) > (getScoreWithId(gameId)[1] ?? 0)
                    ? '600'
                    : '300',
              }}
            >
              {(getScoreWithId(gameId)[0] ?? 0).toLocaleString()}
            </ScoreWrapper>
          </TeamsWrapper>
          <HorizontalSpacing width={spacing} />
          <TeamsWrapper onClick={() => handlePressTeam(1)}>
            <TeamWrapper>
              <TeamEmojiPlayer>
                {getGameWithId(gameId)[0]?.teams[1]?.players[0]?.emoji}
              </TeamEmojiPlayer>
              <TeamEmojiPlayer>
                {getGameWithId(gameId)[0]?.teams[1]?.players[1]?.emoji}
              </TeamEmojiPlayer>
            </TeamWrapper>
            <ScoreWrapper
              style={{
                fontWeight:
                  (getScoreWithId(gameId)[1] ?? 0) > (getScoreWithId(gameId)[0] ?? 0)
                    ? '600'
                    : '300',
              }}
            >
              {(getScoreWithId(gameId)[1] ?? 0).toLocaleString()}
            </ScoreWrapper>
          </TeamsWrapper>
        </LineTeamsWrapper>
        <VerticalSpacing height={spacing} />
        <StyledScrollView
        // ref={scrollViewRef}
        // showsHorizontalScrollIndicator={false}
        // showsVerticalScrollIndicator={false}
        // onContentSizeChange={() => scrollViewRef.current?.scrollToEnd({animated: true})}
        >
          {scrollViewContent}
        </StyledScrollView>
      </WrapperContent>
      <WrapperAdd>
        <CustomButton
          text="Ajouter une mène"
          size="large"
          icon={plusCircleOutlineIcon}
          onPress={() => setLocation(`/game/${gameId}/round`)}
        />
      </WrapperAdd>
      <BottomBar />
    </GlobalWrapper>
  );
};

Game.displayName = 'Game';

//______________ CSS ______________

//______________ TOPBAR ______________
const Titre = styled.span`
  font-size: ${fontSizes.medium}px;
  flex-grow: 1;
  text-align: center;
  color: ${topBarColor};
`;

//______________ WRAPPER ______________
const GlobalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
`;

const WrapperContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 0;
  flex-grow: 1;
  background-color: ${gray};
  border-radius: ${borderRadius * 2}px;
  margin: ${spacing}px;
  margin-top: 0;
  padding: ${spacing}px;
  overflow: hidden;
`;

const WrapperAdd = styled.div`
  margin: 0 ${spacing}px;
  flex-shrink: 0;
`;

//______________ TEAM ______________
const LineTeamsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  background-color: ${gray};
`;
const TeamsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${white};
  align-items: center;
  flex-grow: 1;
  border: solid ${borderColorGame} 1px;
`;

const TeamWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-content: center;
`;

const TeamEmojiPlayer = styled.span`
  font-size: ${fontSizes.extraLarge}px;
  height: ${buttonHeight.medium}px;
  width: ${buttonHeight.medium}px;
  text-align: center;
  line-height: ${buttonHeight.medium}px;
`;

const ScoreWrapper = styled.span`
  font-size: ${fontSizes.xxl}px;
  height: ${buttonHeight.medium}px;
  flex-grow: 1;
  text-align: center;
  line-height: ${buttonHeight.medium}px;
  color: ${black};
`;

//______________ DETAIL SCORE ______________
const StyledScrollView = styled.div`
  background-color: white;
  border: solid 1px ${borderColorGame};
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;

const WrapperLineScore = styled.div`
  display: flex;
  flex-direction: row;
  background-color: white;
`;

const LineScore0 = styled.span`
  width: 0;
  flex-grow: 1;
  font-size: ${fontSizes.medium}px;
  height: ${buttonHeight.medium}px;
  text-align: right;
  line-height: ${buttonHeight.medium}px;
  padding-right: ${spacing * 2}px;
  color: ${pLight};
`;

const LineScoreTotal0 = styled.span`
  width: 0;
  flex-grow: 1;
  font-size: ${fontSizes.medium}px;
  height: ${buttonHeight.medium}px;
  text-align: left;
  line-height: ${buttonHeight.medium}px;
  padding-left: ${spacing * 2}px;
  color: ${black};
`;

const LineScore1 = styled.span`
  width: 0;
  flex-grow: 1;
  font-size: ${fontSizes.medium}px;
  height: ${buttonHeight.medium}px;
  text-align: left;
  line-height: ${buttonHeight.medium}px;
  padding-left: ${spacing * 2}px;
  color: ${pLight};
`;

const LineScoreTotal1 = styled.span`
  width: 0;
  flex-grow: 1;
  font-size: ${fontSizes.medium}px;
  height: ${buttonHeight.medium}px;
  text-align: right;
  line-height: ${buttonHeight.medium}px;
  padding-right: ${spacing * 2}px;
  color: ${black};
`;

const LineIndexMene = styled.span`
  width: 0;
  flex-shrink: 1;
  font-size: ${fontSizes.medium}px;
  height: ${buttonHeight.medium}px;
  text-align: right;
  line-height: ${buttonHeight.medium}px;
  padding-right: ${spacing * 2}px;
  color: ${darkgray};
`;
