import {getGames, Round, Team} from '@src/lib/stores/games_store';
import {Player, usePlayers} from '@src/lib/stores/players_store';

export const getRandomTeams = (numberOfTeams: number, players: Player[]): Team[] => {
  const teams: Team[] = [];
  for (let index = 0; index < numberOfTeams; index++) {
    const team: Team = {id: index, players: []};
    teams.push(team);
  }
  let localSelectedPlayers = [...players];
  while (localSelectedPlayers.length > 0) {
    const randomPlayer =
      localSelectedPlayers[Math.floor(Math.random() * localSelectedPlayers.length)];
    if (!randomPlayer) {
      continue;
    }
    teams.sort((t1, t2) => t1.players.length - t2.players.length);
    teams[0]?.players.push(randomPlayer);
    localSelectedPlayers = localSelectedPlayers.filter(p => p.id !== randomPlayer.id);
  }
  return teams;
};

export const useSortedPlayers = (): Player[] => {
  const [players] = usePlayers();
  return sortPlayerByName(players, 'Nouveau joueur');
};

export const sortPlayerByName = (players: Player[], name_to_exclude?: string): Player[] => {
  const sortedPlayer = [...players];
  sortedPlayer.sort((p1, p2) => {
    if (p1.name === name_to_exclude) {
      return -1;
    } else if (p2.name === name_to_exclude) {
      return 1;
    }
    return p1.name.localeCompare(p2.name);
  });
  return sortedPlayer;
};

export const getScoreWithId = (id: number): number[] => {
  const scores: [number, number] = [0, 0];
  const game = getGames().find(g => g.id === id);
  for (const r of game?.rounds ?? []) {
    const current_score = getscoreWithRound(r);
    scores[0] += current_score[0];
    scores[1] += current_score[1];
  }
  return scores;
};

/* eslint-disable @typescript-eslint/no-magic-numbers */
export const getscoreWithRound = (r: Round): [number, number] => {
  const scores: [number, number] = [0, 0];
  if (r.taker_team_index === 0 || r.taker_team_index === 1) {
    // eslint-disable-next-line no-useless-assignment
    let current_score = 0;
    if (r.successful === 'oui') {
      if (r.coinche) {
        current_score = r.annonce * 2;
      } else if (r.surcoinche) {
        current_score = r.annonce * 4;
      } else {
        current_score = r.annonce;
      }
      scores[r.taker_team_index] = current_score;
    } else if (r.successful === 'non') {
      if (r.coinche) {
        current_score = r.annonce <= 160 ? 160 * 2 : r.annonce * 2;
      } else if (r.surcoinche) {
        current_score = r.annonce <= 160 ? 160 * 4 : r.annonce * 4;
      } else if (r.annonce <= 160) {
        current_score = 160;
      } else {
        current_score = r.annonce;
      }
      const index = r.taker_team_index === 0 ? 1 : 0;
      scores[index] = current_score;
    }
  }
  return scores;
};
/* eslint-enable @typescript-eslint/no-magic-numbers */
