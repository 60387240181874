import {FC, useCallback, useMemo, useState} from 'react';
import {styled} from 'styled-components';
import {useLocation} from 'wouter';

import {removeUndefined} from '@shared/lib/type_utils';

import {UnthemedButton} from '@shared-web/components/core/button';
import {SvgIcon} from '@shared-web/components/core/svg_icon';
import {arrowLeftIcon} from '@shared-web/components/icons/mui/arrow_left_icon';
import {diceIcon} from '@shared-web/components/icons/mui/dice_icon';
import {minusCircleIcon} from '@shared-web/components/icons/mui/minus_circle_icon';
import {plusCircleIcon} from '@shared-web/components/icons/mui/plus_circle_icon';
import {useBooleanQueryString} from '@shared-web/lib/use_query_string';

import {BottomBar} from '@src/components/bottom_bar';
import {CustomButton} from '@src/components/custom_buttons';
import {SelectablePlayer} from '@src/components/selectable_player';
import {VerticalSpacing} from '@src/components/spacing';
import {TopBar} from '@src/components/top_bar';
import {Player, usePlayers} from '@src/lib/stores/players_store';
import {
  borderRadius,
  buttonHeight,
  fontSizes,
  primary,
  secondary,
  spacing,
  topBarButtonWidth,
  topBarColor,
  white,
} from '@src/lib/theme';

export const Selection: FC = () => {
  const [coincheMode] = useBooleanQueryString('coinche', false);
  const setLocation = useLocation()[1];
  const [players] = usePlayers();
  const [numberOfTeams, setNumberOfTeams] = useState(2);
  const [selectedPlayerIds, setSelectedPlayerIds] = useState<number[]>([]);
  const selectedPlayers = useMemo(
    () => removeUndefined(selectedPlayerIds.map(id => players.find(p => p.id === id))),
    [players, selectedPlayerIds]
  );
  const notSelectedPlayers = useMemo(
    () => players.filter(p => !selectedPlayerIds.includes(p.id)),
    [players, selectedPlayerIds]
  );

  const scrollViewContent: JSX.Element[] = [];
  const handleMinusPress = (): void => {
    setNumberOfTeams(curr => Math.max(2, curr - 1));
  };
  const handlePlusPress = (): void => {
    setNumberOfTeams(curr => curr + 1);
  };
  const handleSelected = useCallback((player: Player) => {
    setSelectedPlayerIds(curr =>
      curr.includes(player.id) ? curr.filter(id => id !== player.id) : [...curr, player.id]
    );
  }, []);
  let firstPlayer = true;
  for (const p of selectedPlayers) {
    if (firstPlayer) {
      firstPlayer = false;
    } else {
      scrollViewContent.push(<VerticalSpacing key={p.id * p.id} height={spacing} />);
    }
    scrollViewContent.push(
      <SelectablePlayer
        player={p}
        key={p.id}
        isSelected
        onClick={handleSelected}
      ></SelectablePlayer>
    );
  }
  if (selectedPlayers.length > 0) {
    scrollViewContent.push(<VerticalSpacing key={'selected_separator'} height={spacing * 2} />);
  }
  firstPlayer = true;
  for (const p of notSelectedPlayers) {
    if (firstPlayer) {
      firstPlayer = false;
    } else {
      scrollViewContent.push(<VerticalSpacing key={p.id * p.id} height={spacing} />);
    }
    scrollViewContent.push(
      <SelectablePlayer
        player={p}
        key={p.id}
        isSelected={false}
        onClick={handleSelected}
      ></SelectablePlayer>
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-magic-numbers
  const disabled = coincheMode && selectedPlayers.length !== 4;

  return (
    <>
      <TopBar
        left={
          <CustomButton
            text="Retour"
            icon={arrowLeftIcon}
            onPress={() => setLocation(`/accueil`)}
            width={topBarButtonWidth}
          />
        }
        middle={<Titre>{`Sélection`}</Titre>}
      />
      {coincheMode ? (
        <></>
      ) : (
        <>
          <WrapperNumberOfTeam>
            <TitreNumberOfTeam>Nombre d'équipe</TitreNumberOfTeam>
            <ButtonWrapper>
              <WrapperIcon onClick={() => handleMinusPress()} disabled={numberOfTeams <= 2}>
                <SvgIcon icon={minusCircleIcon} size={fontSizes.medium} color={primary} />
              </WrapperIcon>
              <WrapperTextNumberOfTeams>{numberOfTeams}</WrapperTextNumberOfTeams>
              <WrapperIcon onClick={() => handlePlusPress()}>
                <SvgIcon icon={plusCircleIcon} size={fontSizes.medium} color={primary} />
              </WrapperIcon>
            </ButtonWrapper>
          </WrapperNumberOfTeam>
          <VerticalSpacing height={spacing} />
        </>
      )}
      <StyledScrollView>{scrollViewContent}</StyledScrollView>
      <WrapperBottomButton>
        <CustomButton
          text="Lancer le tirage"
          icon={diceIcon}
          size="large"
          disabled={disabled}
          onPress={() =>
            setLocation(
              `/tirage?teams=${numberOfTeams}&players=${selectedPlayers.map(p => p.id)}&coinche=${coincheMode ? '1' : '0'}`
            )
          }
        />
      </WrapperBottomButton>

      <BottomBar />
    </>
  );
};
Selection.displayName = 'Selection';

const Titre = styled.span`
  font-size: ${fontSizes.medium}px;
  flex-grow: 1;
  text-align: center;
  color: ${topBarColor};
  margin-right: ${topBarButtonWidth}px;
`;

const StyledScrollView = styled.div`
  flex-grow: 1;
  margin: ${spacing}px;
  margin-top: 0;
  overflow-y: auto;
`;

const WrapperBottomButton = styled.div`
  margin: ${spacing}px;
  margin-top: 0;
`;

const WrapperNumberOfTeam = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: row;
  flex-shrink: 0;
  background-color: ${primary};
  padding-right: ${spacing}px;
`;

const TitreNumberOfTeam = styled.span`
  flex-grow: 1;
  line-height: ${buttonHeight.medium}px;
  font-size: ${fontSizes.medium}px;
  margin-left: ${spacing * 2}px;
  color: ${white};
`;

const ButtonWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  padding: ${spacing}px;
`;

const WrapperTextNumberOfTeams = styled.div`
  border-radius: ${borderRadius}px;
  font-size: ${fontSizes.medium}px;
  margin-left: ${spacing}px;
  margin-right: ${spacing}px;
  background-color: ${white};
  width: ${buttonHeight.medium}px;
  line-height: ${buttonHeight.medium}px;
  text-align: center;
  font-size: ${fontSizes.medium}px;
`;

const WrapperIcon = styled(UnthemedButton)`
  height: ${buttonHeight.medium}px;
  width: ${buttonHeight.medium}px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: ${borderRadius}px;
  background-color: ${secondary};
`;
