import {FC, useCallback} from 'react';
import {styled} from 'styled-components';

import {SvgIcon} from '@shared-web/components/core/svg_icon';
import {checkCircleIcon} from '@shared-web/components/icons/mui/check_circle_icon';

import {Player} from '@src/lib/stores/players_store';
import {
  borderRadius,
  buttonHeight,
  fontSizes,
  inputBackgroundColor,
  pastilleBackgroundColor,
  pastilleSelectdBackgroundColor,
  spacing,
} from '@src/lib/theme';

interface SelectablePlayerProps {
  player: Player;
  isSelected: boolean;
  onClick: (player: Player) => void;
}

export const SelectablePlayer: FC<SelectablePlayerProps> = props => {
  const {player, isSelected, onClick} = props;

  const handleClick = useCallback(() => onClick(player), [onClick, player]);

  return (
    <PlayerWrapper onClick={handleClick}>
      <PlayerEmoji>{player.emoji}</PlayerEmoji>
      <PlayerText>{player.name}</PlayerText>
      <WrapperIconCheck>
        <SvgIcon
          key="icon"
          icon={checkCircleIcon}
          size={buttonHeight.small}
          color={isSelected ? pastilleSelectdBackgroundColor : pastilleBackgroundColor}
        />
      </WrapperIconCheck>
    </PlayerWrapper>
  );
};

SelectablePlayer.displayName = 'SelectablePlayer';

const PlayerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: ${spacing}px;
  border-radius: ${borderRadius * 2}px;
  background-color: ${pastilleBackgroundColor};
`;

const PlayerEmoji = styled.span`
  flex-shrink: 0;
  background-color: ${inputBackgroundColor};
  font-size: ${fontSizes.medium}px;
  height: ${buttonHeight.medium}px;
  width: ${buttonHeight.medium}px;
  text-align: center;
  line-height: ${buttonHeight.medium}px;
`;

const PlayerText = styled.span`
  flex-grow: 1;
  background-color: ${inputBackgroundColor};
  font-size: ${fontSizes.medium}px;
  height: ${buttonHeight.medium}px;
  width: ${buttonHeight.medium}px;
  text-align: left;
  line-height: ${buttonHeight.medium}px;
`;

const WrapperIconCheck = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: ${fontSizes.medium}px;
  height: ${buttonHeight.medium}px;
  width: ${buttonHeight.medium}px;
  background-color: ${inputBackgroundColor};
`;
